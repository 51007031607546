//Login Screen Messages
export const err_invalidPhone = "Enter valid Phone number";
export const err_invalidOtp = "Enter Valid OTP";
export const err_invalidQuery = "Fields are empty (or) max field length exceeded";
export const err_invalidEditAddress = "Address validation failed, correct errors and submit again";
export const err_invalidAddAddress = "Address validation failed, correct errors and submit again";
export const err_invalidL3Sel = "Select one Level 3 Category to view Products";
export const err_no_tailors = "No tailors found near by";
export const err_invalidLocation = "Couldn't determine your location, point your location in the map";
export const err_no_boutiques = "No stores found near by";
export const fieldsRequired = "All Fields are Required";
export const err_no_location = "Couldn't determine your current location due to location settings, choose location manually";
export const err_max_qty = "Max quantity exceeded, for bulk orders contact us";
export const err_invalid_promo = "Invalid Promocode";
export const err_no_orders = "No Order Found, continue shopping";
export const err_no_slot = "Select your slot for Measurement";
export const err_no_import_data = "No Measurement Data Found";
export const err_no_push_notification = "Couldn't register your device for push notifications";