import { FileOpener } from '@ionic-native/file-opener/ngx';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { NgOtpInputModule } from 'ng-otp-input';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
// import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { NgxPaginationModule } from 'ngx-pagination';
import { DirectivesDirective } from './directives.directive';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';

@NgModule({
  declarations: [AppComponent, DirectivesDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxIonicImageViewerModule,
    NgOtpInputModule,
    NgxPaginationModule,
    // SmsRetriever
  ],
  providers: [
    StatusBar,
    FileOpener,
    Deeplinks,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
