/* Reviewed */
import { AuthenticationService } from './services/authentication.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { Router } from '@angular/router';
import { Platform, IonRouterOutlet, AlertController, NavController } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Location } from '@angular/common';
import { PluginListenerHandle } from '@capacitor/core';
import { USER_NAME, USER_PROFILE, USER_EMAIL, USER_MOBILE, gc_nli_wish_list, FCM_TOKEN, gc_bdt_return_policy, gc_bdt_privacy_policy, gc_pndt_order_status, gc_bdt_terms_policy, gc_nli_otr_orders, defaultProfileUrl, gc_nli_home, gc_nli_profile, gc_nli_orders, gc_nli_terms, gc_nli_return, gc_nli_privacy, gc_nli_logout, gc_nli_support_list, gc_pndt_customer_support, gc_app_version, gc_pndt_new_order_confirm } from './constant/constants';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { err_no_push_notification } from './constant/Messages';
import { Storage } from '@capacitor/storage';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { NgZone } from '@angular/core';

// const { PushNotifications, Network, Storage, App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  userName: any;
  defaultProfileUrl = defaultProfileUrl;
  userNumber: any;
  userEmail: any;
  gc_app_version = gc_app_version;
  userProfile: any;
  public selectedIndex = 0;
  networkStatus: any;

  networkListener: PluginListenerHandle;

  gc_nli_profile = gc_nli_profile;
  gc_nli_home = gc_nli_home;
  gc_nli_orders = gc_nli_orders;
  gc_nli_otr_orders = gc_nli_otr_orders;
  gc_nli_return = gc_nli_return;
  gc_nli_terms = gc_nli_terms;
  gc_nli_privacy = gc_nli_privacy;
  gc_nli_logout = gc_nli_logout;
  gc_nli_support_list = gc_nli_support_list;
  gc_nli_wish_list = gc_nli_wish_list;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    private authService: AuthenticationService,
    public alertController: AlertController,
    private location: Location,
    protected deeplinks: Deeplinks,
    private zone: NgZone,
    protected navController: NavController
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.backButtonAlert();
    });
  }

  async backButtonAlert() {
    if (!this.routerOutlet.canGoBack()) {
      // check there is no way to go back
      App.exitApp();
    } else {
      // goes back
      this.location.back();
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.setUpDeepLinks();
    });
    this.platform.resume.subscribe(async () => {

    });
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".in").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  setUpDeepLinks() {
    this.deeplinks.route({ '/order-response/:flag': 'order-response' }).subscribe(
      match => {
        // Create our internal Router path by hand
        const internalPath = `/${match.$route}/${match.$args['flag']}`;

        // Run the navigation in the Angular zone
        this.zone.run(() => {
          this.router.navigateByUrl(internalPath);
        });
      },
      nomatch => {
        // nomatch.$link - the full link data
        console.error("Got a deeplink that didn't match", nomatch);
      }
    );
  }

  async gotoPage(index: number) {
    switch (index) {
      case gc_nli_profile:
        this.router.navigateByUrl('tab3');
        break;
      case gc_nli_orders:
        this.router.navigateByUrl('order');
        break;
      case gc_nli_otr_orders:
        this.router.navigateByUrl('other-orders');
        break;
      case gc_nli_logout:
        await this.authService.logout();
        this.authService.gotoLogin();
        break;
      case gc_nli_return:
        this.router.navigateByUrl('replace-policy/' + gc_bdt_return_policy);
        break;
      case gc_nli_privacy:
        this.router.navigateByUrl('replace-policy/' + gc_bdt_privacy_policy);
        break;
      case gc_nli_terms:
        this.router.navigateByUrl('replace-policy/' + gc_bdt_terms_policy);
        break;
      case gc_nli_support_list:
        this.router.navigateByUrl('support-list');
        break;
      case gc_nli_wish_list:
        this.router.navigateByUrl('wishlist-page');
        break;
      case gc_nli_home:
      default:
        this.router.navigateByUrl('tab1');
        break;
    }
  }

  async setupPushNotification() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        this.authService.showToast(err_no_push_notification, 0);
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      Storage.set({ key: FCM_TOKEN, value: token.value });
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        const alert = await this.alertController.create({
          cssClass: 'my-custom-class',
          header: notification.title,
          message: notification.body,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: async (blah) => {
                await alert.dismiss();
              }
            }, {
              text: 'Okay',
              handler: async () => {
                await alert.dismiss();
                this.handlePushNotificationClick(notification);
              }
            }
          ]
        });
        await alert.present();
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.handlePushNotificationClick(notification);
      },
    );
  }

  ngOnInit() {
    this.setupPushNotification();
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status;
      if (!this.networkStatus.connected) {
        this.router.navigateByUrl('no-internet');
      }
    });

    this.authService.authInfo.subscribe((data) => {
      this.getProfileData();
    });

    this.getNetWorkStatus();
  }

  handlePushNotificationClick(notification) {
    if (notification.notification.data != null) {
      if (notification.notification.data.notificationType != null) {
        try {
          var ntype = parseInt(notification.notification.data.notificationType);
          switch (ntype) {
            case gc_pndt_order_status:
              if (notification.notification.data.orderHeaderId != null && notification.notification.data.orderServiceId != null) {
                this.router.navigateByUrl('orderdetail/' + notification.notification.data.orderHeaderId + "/" + notification.notification.data.orderServiceId);
              }
              break;
            case gc_pndt_customer_support:
              if (notification.notification.data.supportID != null) {
                this.router.navigateByUrl('support-view/' + notification.notification.data.supportID);
              }
              break;
            case gc_pndt_new_order_confirm:
              this.router.navigateByUrl('order-response/1');
              break;
            default:

              break;
          }
        } catch (error) {

        }
      } else {

      }
    }
  }

  async getNetWorkStatus() {
    this.networkStatus = await Network.getStatus();
    if (!this.networkStatus.connected) {
      this.router.navigateByUrl('no-internet');
    }
  }

  async getProfileData() {
    const profileImg = await Storage.get({ key: USER_PROFILE });
    if (profileImg && profileImg.value) {
      this.userProfile = profileImg.value;
    } else {
      this.userProfile = this.defaultProfileUrl;
    }

    const userNumber = await Storage.get({ key: USER_MOBILE });
    if (userNumber && userNumber.value) {
      this.userNumber = userNumber.value;
    }

    const userEmail = await Storage.get({ key: USER_EMAIL });
    if (userEmail && userEmail.value) {
      this.userEmail = userEmail.value;
    }

    const username = await Storage.get({ key: USER_NAME });
    if (username && username.value) {
      this.userName = username.value;
    }
  }
}

