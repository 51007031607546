/* Reviewed */
import { BASEURL } from './../constant/constants';
import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Storage } from '@capacitor/storage';
import { ToastController, LoadingController } from '@ionic/angular';
import { TOKEN_KEY, USER_NAME, USER_EMAIL, USER_MOBILE, USER_PROFILE } from '../constant/constants';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService implements OnInit {
  loading: any;
  alert: any;
  refreshed: boolean = false;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  authInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  cartInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(private http: HttpClient,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private router: Router) {
    this.loadToken();
  }

  // call this to update the cart bottom details
  async callCartUpdate() {
    this.cartInfo.next(true);
    return null;
  }

  // call this to update the side nav top details
  async callUpdate() {
    this.authInfo.next(true);
    return null;
  }

  ngOnInit() {
    this.loadToken();
  }

  gotoLogin() {
    this.router.navigate(['login'], { replaceUrl: true });
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  login(formData: any): Observable<any> {
    return this.http.post(`${BASEURL}/user/check-otp`, formData).pipe(
      tap(async (res: any) => {
        this.isAuthenticated.next(true);
        this.token = res.token;
        Storage.set({ key: TOKEN_KEY, value: this.token });
      })
    )
  }

  sendToken() {
    this.getToken().then(result => {
      var token = result.value;
      return token;
    });
  }


  sendUserName() {
    this.getUserName().then(result => {
      var userName = result.value;
      return userName;
    })
  }


  sendEmail() {
    this.getEmail().then(result => {
      var userEmail = result.value;
      return userEmail;
    })
  }

  async getMobile() {
    const userMobile = await Storage.get({ key: USER_MOBILE });
    if (userMobile && userMobile.value) {
      return userMobile;
    }
  }

  async getEmail() {
    const userEmail = await Storage.get({ key: USER_EMAIL });
    if (userEmail && userEmail.value) {
      return userEmail;
    }
  }

  async getUserName() {
    const userName = await Storage.get({ key: USER_NAME });
    if (userName && userName.value) {
      return userName;
    }
  }

  async getToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    return token;
  }

  async getProfileUrl() {
    const userProfile = await Storage.get({ key: USER_PROFILE });
    if (userProfile && userProfile.value) {
      var img = userProfile.value;
      return img;
    }
  }


  sendMobile() {
    this.getMobile().then(result => {
      var userMobile = result.value;
      return userMobile;
    })
  }


  // sendProfileUrl() {
  //   this.getProfileUrl().then(result => {
  //     var userProfileUrl = result.value;
  //     return userProfileUrl;
  //   })
  // }

  getheaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'x-auth-token': this.token
      })
    };
    return httpOptions;
  }

  /* Reviewed */
  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.logoutApi();
    return this.deleteStorage();
  }

  /* Reviewed */
  async logoutApi() {
    const data = {
      token: this.token
    }
    await this.startLoading();
    this.http.put(BASEURL + "/user/logout", data, (this.getheaders())).subscribe(async (res: any) => {
      await this.dismissLoading();
      if (!res.success) {
        //called when logout fails in server
        await this.showToast(res.message, 2);
      }
    },
      async (res) => {
        await this.dismissLoading();
        await this.showToast(res.error.message, 2);
      }
    );
  }

  /* Reviewed */
  deleteStorage(): Promise<void> {
    Storage.remove({ key: USER_EMAIL });
    Storage.remove({ key: USER_MOBILE });
    Storage.remove({ key: USER_NAME });
    Storage.remove({ key: USER_PROFILE });
    return Storage.remove({ key: TOKEN_KEY });
  }

  /* Reviewed */
  async startLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-loading-class',
      message: 'Please wait...',
    });
    await this.loading.present();
  }

  /* Reviewed */
  async startLoadingPayment() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-loading-class',
      message: 'Checking Payment...',
    });
    await this.loading.present();
  }

  /* Reviewed */
  dismissLoading() {
    this.loading.dismiss();
  }

  /* Reviewed */
  async showToast(message: string, type: number) {
    switch (type) {
      case 0:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          color: 'warning',
          buttons: [
            {
              side: 'start',
              icon: 'alert-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      case 1:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          color: 'success',
          buttons: [
            {
              side: 'start',
              icon: 'checkmark-done-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      case 2:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          color: 'danger',
          buttons: [
            {
              side: 'start',
              icon: 'alert-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      default:
        break;
    }
  }

}
